import Modal from 'react-bootstrap/Modal';
import { useState, useEffect } from 'react';
import { Form, Row, Col, Button, Image } from 'react-bootstrap';
import { useData } from '../../context/DataContext';
import API_ENDPOINTS from '../../config/apiConfig';
import { fetchFromApi } from '../../config/api';

function AddProductModal(props) {
    const { artistData, categoriesData, fetchProductsData } = useData();


    const [productData, setProductData] = useState({
        product_name: '',
        product_image: '',
        product_cost: '',
        image_dimensions: '',
        product_description: '',
        product_short_description: '',
        product_discount_percentage: 0,
        product_final_price: '',
        product_artist: 0,
        product_category_id: 0,
    });

    const [thumbnailImages, setThumbnailImages] = useState([]);
    const [isLoadingThumbnails, setIsLoadingThumbnails] = useState(false);

    const [error, setError] = useState("");
    const [loading, setLoading] = useState(false);
    const [successMsg, setSuccessMsg] = useState("");

    useEffect(() => {
        const { product_cost, product_discount_percentage } = productData;

        if (product_cost && product_discount_percentage) {
            const finalPrice = product_cost - (product_cost * product_discount_percentage) / 100;
            setProductData((prevData) => ({
                ...prevData,
                product_final_price: finalPrice.toFixed(0),
            }));
        } else {
            setProductData((prevData) => ({
                ...prevData,
                product_final_price: '',
            }));
        }
    }, [productData.product_cost, productData.product_discount_percentage]);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setProductData({
            ...productData,
            [name]: value,
        });
    };

    const handleImageChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setProductData({
                    ...productData,
                    product_image: reader.result,
                });
            };
            reader.readAsDataURL(file);
        }
    };

    const handleThumbnailChange = (e) => {
        setIsLoadingThumbnails(true);
        const files = Array.from(e.target.files);
        const promises = files.map((file) => {
            return new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.onloadend = () => {
                    resolve(reader.result);
                };
                reader.onerror = reject;
                reader.readAsDataURL(file);
            });
        });

        Promise.all(promises)
            .then((images) => {
                const currentThumbnails = thumbnailImages || [];
                const newThumbnails = images.map((image, index) => ({
                    order: currentThumbnails.length + index + 1,
                    image,
                }));
                setThumbnailImages([...currentThumbnails, ...newThumbnails]);
            })
            .catch((error) => {
                console.error('Error uploading thumbnails: ', error);
            })
            .finally(() => {
                setIsLoadingThumbnails(false);
            });
    };

    const handleRemoveThumbnail = (indexToRemove) => {
        const updatedImages = thumbnailImages
            .filter((_, index) => index !== indexToRemove)
            .map((item, index) => ({ ...item, order: index + 1 }));
        setThumbnailImages(updatedImages);
    };

    const handleSubmit = async (e) => {

        e.preventDefault();
        if (!productData.product_name || !productData.product_cost || !productData.product_image) {
            alert('Please fill out all required fields ');
            return;
        }



        try {
            setLoading(true);
            const formBody = {
                ...productData,
                thumblist: JSON.stringify(thumbnailImages),
            };




            const data = await fetchFromApi(API_ENDPOINTS.INSERT_PRODUCT, 'POST', formBody);

            if (data.status === "success") {
                setSuccessMsg("Product  added Successfully");

                setTimeout(async () => {
                    setSuccessMsg("");
                    props.onHide();
                    await fetchProductsData();
                }, 2000);
            } else {
                setError("Failed to update artist. Please check the details and try again.");
            }
        } catch (error) {
            setError("An error occurred. Please try again.");
            console.error(error);
        } finally {
            setTimeout(async () => {
                setLoading(false);
            }, 2000);
        }

    };

    const isFormValid =
        productData.product_name &&
        productData.product_artist > 0 &&
        productData.product_category_id > 0 &&
        productData.product_cost &&
        productData.product_discount_percentage !== "" &&
        productData.product_cost <= 9999;

    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title"
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Add Product
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form onSubmit={handleSubmit}>
                    <Row className="mb-3">
                        <Col xs={12} md={6}>
                            <Form.Group controlId="formProductName">
                                <Form.Label>
                                    Product Name <span className="text-danger">*</span>
                                </Form.Label>
                                <Form.Control
                                    type="text"
                                    name="product_name"
                                    value={productData.product_name}
                                    onChange={handleInputChange}
                                    placeholder="Enter product name"
                                />
                            </Form.Group>
                        </Col>
                        <Col sm={6}>
                            <Form.Group controlId="artistFilter">
                                <Form.Label className="fw-bold mb-2">
                                    Select Category <span className="text-danger">*</span>
                                </Form.Label>
                                <Form.Control
                                    as="select"
                                    value={productData.product_category_id}
                                    onChange={handleInputChange}
                                    name="product_category_id"
                                >
                                    <option value="0">Select an Category</option>

                                    {categoriesData.map((category) => (
                                        <option key={category.id} value={category.id}>
                                            {category.category_name}
                                        </option>
                                    ))}
                                </Form.Control>
                            </Form.Group>
                        </Col>
                    </Row>

                    <Row className="mb-3">
                        <Col sm={6}>
                            <Form.Group controlId="artistFilter">
                                <Form.Label className="fw-bold mb-2">
                                    Select Artist <span className="text-danger">*</span>
                                </Form.Label>
                                <Form.Control
                                    as="select"
                                    value={productData.product_artist}
                                    onChange={handleInputChange}
                                    name="product_artist"
                                >
                                    <option value="0">Select an Artist</option>
                                    {artistData.map((artist) => (
                                        <option key={artist.id} value={artist.id}>
                                            {artist.name}
                                        </option>
                                    ))}
                                </Form.Control>
                            </Form.Group>
                        </Col>

                        <Col xs={12} md={6}>
                            <Form.Group controlId="formProductDimensions">
                                <Form.Label>
                                    Image Dimensions
                                </Form.Label>
                                <Form.Control
                                    type="text"
                                    name="image_dimensions"
                                    value={productData.image_dimensions}
                                    onChange={handleInputChange}
                                    placeholder="Enter dimensions (e.g., 24 W x 36 H in.)"
                                />
                            </Form.Group>
                        </Col>
                    </Row>

                    <Row className="mb-3">

                        <Col xs={12} md={6}>
                            <Form.Group controlId="formProductDescription">
                                <Form.Label>Product Description</Form.Label>
                                <Form.Control
                                    as="textarea"
                                    name="product_description"
                                    value={productData.product_description}
                                    onChange={handleInputChange}
                                    rows={4}
                                    placeholder="Enter a detailed product description"
                                />
                            </Form.Group>
                        </Col>


                        <Col xs={12} md={6}>
                            <Form.Group controlId="formProductShortDescription">
                                <Form.Label>Product Short Description</Form.Label>
                                <Form.Control
                                    as="textarea"
                                    name="product_short_description"
                                    value={productData.product_short_description}
                                    onChange={handleInputChange}
                                    rows={4}
                                    placeholder="Enter a short description"
                                />
                            </Form.Group>
                        </Col>
                    </Row>

                    <Row className="mb-3">
                        <Col xs={12} md={4}>
                            <Form.Group controlId="formProductCost">
                                <Form.Label>
                                    Product Cost ($) <span className="text-danger">*</span>
                                </Form.Label>
                                <Form.Control
                                    type="text"
                                    name="product_cost"
                                    value={productData.product_cost || ''}
                                    onChange={(e) => {
                                        const value = e.target.value;
                                        if (/^\d{0,4}$/.test(value)) {
                                            handleInputChange(e);
                                        }
                                    }}
                                    placeholder="Enter product cost"
                                />
                            </Form.Group>
                        </Col>

                        <Col xs={12} md={4}>
                            <Form.Group controlId="formProductDiscount">
                                <Form.Label>
                                    Product Discount (%) <span className="text-danger">*</span>
                                </Form.Label>
                                <Form.Control
                                    type="text"
                                    name="product_discount_percentage"
                                    value={productData.product_discount_percentage || ''}
                                    onChange={(e) => {
                                        const value = e.target.value;
                                        if (/^\d{0,2}$/.test(value)) {
                                            handleInputChange(e);
                                        }
                                    }}
                                    placeholder="Enter discount percentage"
                                />
                            </Form.Group>
                        </Col>

                        <Col xs={12} md={4}>
                            <Form.Group controlId="formProductFinalPrice">
                                <Form.Label>Final Price ($)</Form.Label>
                                <Form.Control
                                    type="number"
                                    name="product_final_price"
                                    value={productData.product_final_price || ''}
                                    onChange={handleInputChange}
                                    placeholder="Enter final price"
                                    disabled
                                />
                            </Form.Group>
                        </Col>
                    </Row>

                    <Row className="mb-3">
                        <Col xs={12} md={6}>
                            <Form.Group controlId="formProductImage">
                                <Form.Label>Product Image <span className="text-danger">*</span></Form.Label>
                                <Form.Control
                                    type="file"
                                    accept="image/*"
                                    onChange={handleImageChange}
                                />
                                {productData.product_image && (
                                    <Image
                                        src={productData.product_image}
                                        fluid
                                        className="mt-3"
                                        style={{ width: '150px', height: '150px' }}
                                    />
                                )}
                            </Form.Group>
                        </Col>
                        <Col xs={12} md={6}>
                            <Form.Group controlId="formProductThumbnails">
                                <Form.Label>Product Thumbnails</Form.Label>
                                <Form.Control
                                    type="file"
                                    accept="image/*"
                                    multiple
                                    onChange={handleThumbnailChange}
                                />
                                {isLoadingThumbnails && <div>Loading thumbnails...</div>}
                                {thumbnailImages?.length > 0 && (
                                    <div className="mt-3" style={{ display: 'flex', flexWrap: 'wrap', gap: '10px' }}>
                                        {thumbnailImages.map((item, index) => (
                                            <div key={index} style={{ width: '30%', textAlign: 'center' }}>
                                                <div className="d-flex flex-column align-items-center">
                                                    <Image
                                                        src={item.image}
                                                        fluid
                                                        style={{
                                                            width: '100px',
                                                            height: '100px',
                                                            objectFit: 'cover',
                                                            border: '1px solid #ccc',
                                                            padding: '5px',
                                                            borderRadius: '5px',
                                                        }}
                                                    />
                                                    <Button
                                                        variant="danger"
                                                        size="sm"
                                                        className="thumbnail-remove-btn mt-2"
                                                        onClick={() => handleRemoveThumbnail(index)}
                                                    >
                                                        &times;
                                                    </Button>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                )}
                            </Form.Group>
                        </Col>
                    </Row>
                </Form>
                {error && <div className="text-danger text-center">{error}</div>}
                {successMsg && <div className="text-success text-center">{successMsg}</div>}
            </Modal.Body>

            <Modal.Footer>
                <Button
                    type="button"
                    variant="outline-success"
                    className="f-14"
                    onClick={handleSubmit}
                    disabled={!isFormValid || loading}
                >
                    {loading ? "Adding..." : "Add"}
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

export default AddProductModal;
